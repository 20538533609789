<div
  *ngIf="!loading && !isEmpty; else loadingBlock"
  class="bg-white w-full border-1 border-round border-gray-300"
>
  <div
    [ngClass]="{
      'mxn-notify-detailHeader': true,
      flex: true,
      'justify-content-between': true,
      'align-items-center': true,
      'mxn-notify-detail-header-left-border-completed':
        notification.statusBadge === 'completed',
      'mxn-notify-detail-header-left-border-inprogress':
        notification.statusBadge === 'in-progress',
      'mxn-notify-detail-header-left-border-notstarted':
        notification.statusBadge === 'not-started'
    }"
  >
    <div class="flex justify-content-between align-items-center gap-2">
      <div class="mxn-notify-pImg">
        <img
          [src]="
            notification?.gender === 'F'
              ? '/assets/profile-placeholder-female.svg'
              : '/assets/profile-placeholder.svg'
          "
          alt="profile"
        />
      </div>
      <div class="mxn-notify-pname">{{ notification.patientName }}</div>
      <div class="mxn-notify-extInfo">
        ({{ notification.age }}) {{ notification.gender }}
      </div>
    </div>
    <div
      class="mxn-overlay mxn-notify-statusBadge"
      [ngClass]="{
        discharge: notification.statusBadge === 'completed',
        inprogress: notification.statusBadge === 'not-started',
        notstarted: notification.statusBadge === 'in-progress'
      }"
    >
      <img
        [src]="statusBadgeIconMap.get(notification.statusBadge)"
        alt="Badge"
      />
      {{ notification.statusBadge | keyFormatter }}
      <div class="mxn-overlay-ddm">
        <div class="mxn-overlay-items">
          <ul>
            <li
              [ngClass]="{
                notstarted: notification.statusBadge === 'not-started'
              }"
              (click)="onStatusChange(notification.id, 'not-started')"
              (keydown)="onStatusChange(notification.id, 'not-started')"
            >
              <span class="mxn-sortby-icon"
                ><img src="/assets/hovered.svg" alt="Not Started" /></span
              >Not Started
            </li>

            <li
              [ngClass]="{
                inprogress: notification.statusBadge === 'in-progress'
              }"
              (click)="onStatusChange(notification.id, 'in-progress')"
              (keydown)="onStatusChange(notification.id, 'in-progress')"
            >
              <span class="mxn-sortby-icon"
                ><img src="/assets/inprogress.svg" alt="In Progress" /></span
              >In Progress
            </li>

            <li
              [ngClass]="{
                discharge: notification.statusBadge === 'completed'
              }"
              (click)="onStatusChange(notification.id, 'completed')"
              (keydown)="onStatusChange(notification.id, 'completed')"
            >
              <span class="mxn-sortby-icon"
                ><img src="/assets/check.svg" alt="Completed" /></span
              >Completed
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <mxn-detail-panel [contentTemplate]="demographicsDetail"></mxn-detail-panel>
  <ng-template #demographicsDetail>
    <mxn-detail-info [details]="notification.demographics"></mxn-detail-info>
  </ng-template>

  <div class="mxn-detail-panel">
    <h3 class="mxn-panel-heading">Organization Panels</h3>
    <div class="mxn-inner-panel">
      <div class="mxn-detail-table-wraper flex justify-content-between gap-4">
        <div class="col-12 flex flex-column gap-2 py-0">
          <ul class="pl-2 m-0">
            <li
              *ngFor="let panel of notification.organisationPanels"
              class="mxn-td-info py-1"
            >
              {{ panel.panelName }}
              <span class="mxn-td-label font-normal">MRN:</span>
              {{ panel.mrn }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ng-container
    *ngIf="
      notification.eventType === 'COVID' &&
      notification?.laboratoryResults &&
      notification?.laboratoryResults?.length > 0
    "
  >
    <mxn-detail-panel
      heading="Laboratory Result"
      [contentTemplate]="laboratoryTable"
      class="max-nobordered-panel"
    ></mxn-detail-panel>
  </ng-container>

  <ng-template #laboratoryTable>
    <mxn-table
      [columns]="laboratoryColumns"
      [data]="notification.laboratoryResults"
      [headerClasses]="labHeaderClasses"
      [bodyClasses]="labBodyClasses"
      [nestedColumns]="laboratoryNestedColumns"
      [nestedHeaderClasses]="nestedLabHeaderClasses"
      [nestedBodyClasses]="nestedLabBodyClasses"
    >
    </mxn-table>
  </ng-template>

  <div class="mxn-detail-panel" *ngIf="notification.eventType !== 'COVID'">
    <h3 class="mxn-panel-heading">Encounter</h3>
    <div class="mxn-inner-panel p-0">
      <div class="p-3">
        <mxn-detail-info [details]="notification.encounter"></mxn-detail-info>
      </div>
      <div
        *ngIf="notification.conditions && notification.conditions.length > 0"
      >
        <h3 class="mxn-panel-heading border-top-1 border-gray-300 pt-3 pl-3">
          Diagnosis Details
        </h3>
        <div class="max-fulltable diagnosis_detailsTbl">
          <mxn-table
            [columns]="diagnosisColumns"
            [data]="notification.conditions"
            [headerClasses]="diagnosisHeaderClasses"
            [bodyClasses]="diagnosisBodyClasses"
          >
          </mxn-table>
        </div>
      </div>
    </div>
  </div>

  <div class="mxn-detail-panel" #commentsSection>
    <h3 class="mxn-panel-heading">Comments</h3>
    <div class="mxn-inner-panel p-3 pr-0">
      <div class="mxn-detail-table-wraper">
        <mxn-comment-card
          *ngFor="let comment of notification.comments"
          [comment]="comment"
          (onClickEditComment)="onEditComment(comment)"
          (onClickDeleteComment)="onDeleteComment(comment)"
          [notificationId]="notification?.id"
          [startedEditing]="
            editingCommentId !== '' && comment?.id !== editingCommentId
          "
          [isEditable]="comment.isEditable"
          (toggleEditComment)="toggleEditComment(comment)"
          (refreshNotificationDetail)="getNotificationDetails()"
        ></mxn-comment-card>

        <div class="pr-3">
          <div class="mxn-notify-newcomment mt-3">
            <p-inputGroup class="">
              <input
                type="text"
                class="pInputText"
                placeholder="Type comment here...."
                [(ngModel)]="newComment"
              />
              <button
                type="button"
                class="pButton btn-sm"
                (click)="handleAddComment()"
                [disabled]="isSavingNewComment"
              >
                Add Comment
              </button>
            </p-inputGroup>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mxn-detail-panel
    *ngIf="notification?.auditLogs && notification?.auditLogs?.length > 0"
    heading="Notification Audit"
    [contentTemplate]="auditLogTable"
    class="max-nobordered-panel"
  ></mxn-detail-panel>
  <ng-template #auditLogTable>
    <mxn-table
      [columns]="demographicsColumns"
      [data]="notification.auditLogs"
      [headerClasses]="demographicsHeaderClasses"
      [bodyClasses]="demographicsBodyClasses"
    >
    </mxn-table>
  </ng-template>
</div>
<ng-template #loadingBlock>
  <div *ngIf="loading && !isEmpty; else emptyBlock" class="loader">
    <p-progressSpinner ariaLabel="loading"></p-progressSpinner>
  </div>
</ng-template>

<ng-template #emptyBlock>
  <div class="mxn-noNotify-select">
    <img
      src="/assets/notificatin-details-icn.svg"
      alt="Select a notification"
    />
    <p>Select a notification from the list to see full details.</p>
  </div>
</ng-template>

<p-dialog
  [modal]="true"
  [(visible)]="visible"
  styleClass="max-confirmation-dialog"
>
  <div class="flex gap-3 px-4">
    <div class="mxn-popup-icon">
      <div class="mxn-warning">
        <img src="assets/ph_warning-light.svg" alt="warning-light" />
      </div>
    </div>
    <div class="flex-1">
      <h3 class="m-0 mb-2">Alert</h3>
      <p class="m-0">Are you sure you want to delete this comment?</p>
    </div>
  </div>
  <div class="flex justify-content-end gap-2 max-dialog-footer">
    <p-button
      label="Cancel"
      severity="secondary"
      (onClick)="visible = false"
      class="max-dialog-cancel-btn"
    />
    <p-button
      label="Confirm"
      (onClick)="visible = false"
      (onClick)="onDeleteCommentConfirm()"
      class="max-dialog-Confirm-btn"
    />
  </div>
</p-dialog>
