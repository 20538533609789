<ng-container>
  <p-table [value]="data" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of getColumnKeys(); let i = index"
          [ngClass]="headerClasses[i]"
        >
          {{ columns[col] }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-dataObj>
      <tr>
        <td
          *ngFor="let col of getColumnKeys(); let i = index"
          [ngClass]="bodyClasses[i]"
        >
          <ng-container *ngIf="col === 'action'; else diagnosisTypeLookUp">
            <div [innerHTML]="getActionCellData(dataObj[col], col)"></div>
          </ng-container>

          <ng-template #diagnosisTypeLookUp>
            <ng-container *ngIf="col === 'name'; else covidStatusLookUp">
              <div [innerHTML]="lookUpDiagnosisType(dataObj[col])"></div>
            </ng-container>
          </ng-template>

          <ng-template #covidStatusLookUp>
            <ng-container *ngIf="col === 'status'; else elseBlock">
                <div [innerHTML]="lookUpCovidStatus(dataObj[col])"></div>
            </ng-container>
          </ng-template>

            <ng-template #elseBlock>
            {{ dataObj[col] }}
          </ng-template>
        </td>
      </tr>
      <tr *ngIf="dataObj?.testData?.length > 0">
        <td colspan="12" class="">
          <ng-container>
            <p-table
              [value]="dataObj.testData"
              [tableStyle]="{ 'min-width': '50rem' }"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th
                    *ngFor="let col of getNestedColumnKeys(); let i = index"
                    [ngClass]="nestedHeaderClasses[i]"
                  >
                    {{ nestedColumns?.[col] }}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-nestedDataObj>
                <tr>
                  <td
                    *ngFor="let col of getNestedColumnKeys(); let i = index"
                    [ngClass]="nestedBodyClasses[i]"
                  >
                    <ng-container *ngIf="col === 'action' || col === 'resultValue' ; else covidStatusLookUp">
                      <div
                        [innerHTML]="getActionCellData(nestedDataObj[col], col)"
                      ></div>
                    </ng-container>
                    <ng-template #covidStatusLookUp>
                        <ng-container *ngIf="col === 'status'; else messageFlagLookUp">
                            <div [innerHTML]="lookUpCovidStatus(nestedDataObj[col])"></div>
                        </ng-container>
                    </ng-template>
                    <ng-template #messageFlagLookUp>
                      <ng-container *ngIf="col === 'messageFlag'; else elseBlock">
                          <div [innerHTML]="lookUpAbnormalFlag(nestedDataObj[col])"></div>
                      </ng-container>
                  </ng-template>
                  <ng-template #elseBlock>
                      {{ nestedDataObj[col] }}
                    </ng-template>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
