import { Component, Input } from "@angular/core";
import { toCamelCase } from "../../../util/common.util";
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: "mxn-table",
  templateUrl: "./table.component.html",
  styleUrl: "./table.component.scss",
})
export class TableComponent {
  constructor(private readonly sanitizer: DomSanitizer) {
  }

  @Input() columns: { [key: string]: string } = {};
  @Input() nestedColumns?: { [key: string]: string } = {};
  @Input() data: any[] = [];
  toCamelCase = toCamelCase;
  // Default classes for header and body cells
  @Input() headerClasses: string[] = [
    "w-15rem py-2 pr-2 pl-3",
    "w-15rem py-2 px-2",
    "px-2 py-2",
  ];
  @Input() bodyClasses: string[] = [
    "p-2 pl-3 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];
  @Input() nestedHeaderClasses: string[] = [
    "w-15rem py-2 pr-2 pl-4",
    "w-15rem py-2 px-2",
    "px-2 py-2",
  ];
  @Input() nestedBodyClasses: string[] = [
    "p-2 pl-4 mxn-td-label",
    "p-2 mxn-td-label",
    "p-2 mxn-td-info",
  ];

  private diagnosisTypeLookUp = new Map<string, string>([
      ['a', 'Admitting'],
      ['f', 'Final'],
      ['o', 'Other'],
      ['w', 'Working']
  ]);

  private resultStatusLookUp = new Map<string, string>([
    ['c', 'Corrected'],
    ['f', 'Final'],
    ['i', 'Speciman In Lab'],
    ['p', 'Preliminary'],
    ['r', 'Not Verified'],
    ['s', 'Scheduled'],
    ['y', 'No Order'],
    ['z', 'No Record of Patient']
]);

private observationLookUp = new Map<string, string>([
  ['d', 'Deleted'],
  ['f', 'Final'],
  ['i', 'Speciman In Lab'],
  ['r', 'Not Verified'],
  ['s', 'Partial Results'],
]);

private abnormalFlagLookUp = new Map<string, string>([
  ['<', 'Deleted'],
  ['a', 'Abnormal'],
  ['aa', 'Very Abnormal'],
  ['d', 'Significant Down Change'],
  ['h', 'Above High Normal'],
  ['hh', 'Above Upper Panic Limits'],
  ['i', 'Interval'],
  ['ll', 'Below Lower Panic Levels'],
  ['ms', 'Moderately Sensitive'],
  ['r', 'Resists'],
  ['s', 'Sensitive'],
  ['u', 'Significant Change Up'],
  ['vs', 'Very Sensitive'],
  ['l', 'Below Low Normal'],
  ['n', 'Normal'],
]);

  getColumnKeys() {
    return Object.keys(this.columns);
  }
  getNestedColumnKeys() {
    return this.nestedColumns ? Object.keys(this.nestedColumns) : null;
  }
  getShowNestedTable(rowData: any) {
    return rowData?.testData;
  }
  getActionCellData(data: string, col: string) {
    if (!data || data === "") return "";
    if (col === "action") {
      return `${
        data.toLocaleLowerCase().includes("in-progress") ||
        data.toLocaleLowerCase().includes("not-started") ||
        data.toLocaleLowerCase().includes("complete")
          ? `Status updated to ${toCamelCase(data)}`
          : data.toLocaleLowerCase().includes("read")
          ? `Marked as ${toCamelCase(data)}`
          : `${data}`
      }`;
    }

    if (col === "resultValue" && data?.toLocaleLowerCase()?.includes("positive")) {
      return this.sanitizer.bypassSecurityTrustHtml(`<span style="color: #FF637B;">${data}</span>`);
    }

    if (col === "resultValue" && data?.toLocaleLowerCase()?.includes("positive")) {
      return this.sanitizer.bypassSecurityTrustHtml(`<span style="color: #FF637B;">${data}</span>`);
    }

    return data;
  }

  private lookUpCodeStatus(code: string, lookup: Map<string, string>): string {
    if (!code) {
      return code;
    }
    return lookup.get(code.toLowerCase()) || code;
  }

  lookUpObservationStatus(code: string) {
    return this.lookUpCodeStatus(code, this.observationLookUp);
  }

  lookUpAbnormalFlag(code: string) {
    return this.lookUpCodeStatus(code, this.abnormalFlagLookUp);
  }

  lookUpCovidStatus(code: string) {
    return this.lookUpCodeStatus(code, this.resultStatusLookUp);
  }

  lookUpDiagnosisType(code: string) {
    const otherType = this.diagnosisTypeLookUp.get("o");

    if(!code || code === "") {
      return otherType; 
    }
    
    const codeToLower = code.toLowerCase();

    if(this.diagnosisTypeLookUp.has(codeToLower)) {
      return this.diagnosisTypeLookUp.get(codeToLower);
    }

    return otherType;
  }
}
