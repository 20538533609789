import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'notificationFilterCounter'
})
export class NotificationFilterCounter implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;  // If the value is empty, return as is

        // Split the string into words, capitalize the first word, and make the rest lowercase
        const word = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return word;
    }
}
